/* Reset básico */
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 100%;
  scroll-behavior: smooth;
}

body {
  font-family: 'Roboto', sans-serif;
  background-color: #0b0c10;
  color: #c5c6c7;
  line-height: 1.6;
  min-height: 100vh;
  background: linear-gradient(120deg, #0b0c10, #1f2833);
  overflow-x: hidden;
  position: relative;
}

body::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(120deg, #0b0c10, #1f2833);
  z-index: -1;
  animation: gradientShift 10s ease-in-out infinite;
}

/* Animación de cambio de gradiente */
@keyframes gradientShift {
  0%, 100% {
    background: linear-gradient(120deg, #0b0c10, #1f2833);
  }
  50% {
    background: linear-gradient(120deg, #1f2833, #0b0c10);
  }
}

/* Sidebar */
.sidebar {
  background-color: #0b0c10; /* Igual que el contorno negro */
  width: 250px;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  position: fixed;
  z-index: 1000;
}

/* Animación de entrada de la sidebar */
@keyframes slideIn {
  to {
    transform: translateX(0);
  }
}

.profile-image {
  width: 250px;
  height: 250px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 20px;
}

.sidebar .profile img {
  border-radius: 50%;
  width: 150px;
  height: 150px;
  object-fit: cover;
  margin-bottom: 20px;
  border: 3px solid #66fcf1;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.sidebar .profile img:hover {
  transform: scale(1.1);
  box-shadow: 0 0 15px rgba(102, 252, 241, 0.6);
}

.sidebar nav ul {
  list-style: none;
  width: 100%;
  text-align: center;
  padding-top: 20px;
}

.sidebar nav ul li {
  margin: 20px 0;
  opacity: 0;
  animation: fadeInUp 0.5s forwards;
  animation-delay: calc(0.1s * var(--delay));
}

/* Animación de aparición de los elementos del menú */
@keyframes fadeInUp {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.sidebar nav ul li a {
  color: #45a29e;
  text-decoration: none;
  font-size: 18px;
  transition: color 0.3s ease;
}

.sidebar nav ul li a:hover {
  color: #66fcf1;
}

.hire-me {
  margin-top: auto;
  padding: 10px 20px;
  background-color: transparent;
  color: #66fcf1;
  border: 1px solid #66fcf1;
  border-radius: 5px;
  text-align: center;
  text-decoration: none; /* Asegura que el enlace no tenga subrayado */
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.hire-me:hover {
  background-color: #66fcf1;
  color: #0b0c10;
}

/* Main Content */
main {
  margin-left: 250px;
  padding: 20px;
  background-color: #0b0c10;
  min-height: 100vh;
  opacity: 0;
  animation: fadeIn 1s forwards;
  animation-delay: 0.5s;
}

/* Animación de aparición del contenido principal */
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Sections */
section {
  padding: 80px 20px;
  text-align: center;
}

.home {
  background: linear-gradient(120deg, #1f2833, #0b0c10);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100vh;
  overflow: hidden;
  position: relative;
}

.home h1 {
  font-size: 4rem;
  color: #66fcf1;
  text-shadow: 2px 2px 5px #000;
  margin-bottom: 20px;
  opacity: 0;
  animation: fadeIn 2s ease-out forwards;
  animation: fadeInDown 1s forwards;
  animation-delay: 1s;
}

/* Animación de aparición desde arriba */
@keyframes fadeInDown {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.home p {
  font-size: 1.5rem;
  margin: 20px 0;
  color: #c5c6c7;
  text-shadow: 1px 1px 3px #000;
  opacity: 0;
  animation: fadeInDown 1s forwards;
  animation-delay: 1.2s;
}

.social-icons {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  gap: 20px;
  opacity: 0;
  animation: fadeInDown 1s forwards;
  animation-delay: 1.4s;
}

.social-icons a {
  color: #66fcf1;
  transition: color 0.3s ease, transform 0.3s ease;
}

.social-icons a:hover {
  color: #45a29e;
  transform: scale(1.2);
}

/* Skills Section */
/* Skills Section */
.skills {
  background-color: #1f2833;
  padding: 50px 20px;
  text-align: center;
  position: relative;
  color: #c5c6c7;
}

.skills h2 {
  color: #66fcf1;
  font-size: 2.5rem;
  margin-bottom: 40px;
  text-shadow: 2px 2px 5px #000;
  opacity: 0;
  animation: fadeInDown 1s forwards;
}

.skills-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 800px;
  margin: 0 auto;
}

.skill {
  width: 100%;
  margin-bottom: 20px;
  padding: 10px;
  background-color: #0b0c10;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 0.5s forwards;
}

.skill-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  color: #c5c6c7;
}

.skill-info span {
  font-size: 1.2rem;
}

.skill-info .fa-icon {
  margin-right: 15px;
  color: #66fcf1;
}

.progress-bar {
  background-color: #4b4b4b;
  border-radius: 5px;
  overflow: hidden;
}

.progress {
  background-color: #45a29e;
  height: 10px;
  border-radius: 5px;
  width: 0;
  animation: fillProgress 2s ease-out forwards;
}

/* Animación para llenar la barra de progreso */
@keyframes fillProgress {
  from {
    width: 0;
  }
  to {
    width: var(--progress-width);
  }
}

/* Animación de aparición del texto */
@keyframes fadeInUp {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInDown {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* About Section */
.about {
  background-color: #1f2833;
  color: #c5c6c7;
  padding: 50px 20px;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.5);
  margin: 20px 0;
  text-align: left;
  background-image: linear-gradient(120deg, #1f2833 50%, #0b0c10);
  position: relative;
  overflow: hidden;
  transform: translateY(20px);
  opacity: 0;
  animation: fadeInUp 0.7s forwards;
}

.about h2 {
  color: #66fcf1;
  font-size: 2.5rem;
  margin-bottom: 40px;
  text-align: center;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5); 
}

.about p,
.about ul {
  max-width: 800px;
  line-height: 1.6;
  margin: 0 auto;
}

.about ul {
  list-style: none;
}

.about ul li {
  display: flex;
  align-items: center;
  margin: 15px 0;
  font-size: 1.2rem;
}

.skill-icon {
  margin-right: 10px;
  color: #66fcf1;
  font-size: 1.5rem;
  transition: transform 0.3s ease, color 0.3s ease;
}

.about ul li strong {
  color: #ffffff;
}

.about ul li:hover .skill-icon {
  transform: scale(1.2);
  color: #45a29e;
}

.about::before {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background-image: radial-gradient(circle, rgba(255,255,255,0.1) 25%, transparent 25%);
  background-size: 50px 50px;
  z-index: 0;
  opacity: 0.2;
}

.divider {
  height: 2px;
  background-color: #66fcf1;
  margin: 20px 0;
  width: 100%;
  max-width: 200px;
  margin-left: auto;
  margin-right: auto;
}

/* Portfolio Section */
.portfolio {
  background-color: #1f2833;
  padding: 50px 20px;
  position: relative;
}

.portfolio h2 {
  color: #66fcf1;
  font-size: 2.5rem;
  margin-bottom: 40px;
}

.projects-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  max-width: 1000px;
  margin: 0 auto;
}

.project-card {
  background-color: #0b0c10;
  width: 30%;
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 0.5s forwards;
}

.project-card i {
  font-size: 3rem;
  margin-bottom: 15px;
  color: #66fcf1;
  transition: color 0.3s ease;
}

.project-card h3 {
  margin-bottom: 10px;
  color: #66fcf1;
}

.project-card p {
  margin: 10px 0;
}

.project-link {
  color: #66fcf1;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s ease;
}

.project-link:hover {
  color: #45a29e;
}

.project-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.5);
}

/* Services Section */
.services {
  background-color: #1f2833; /* Mantén el mismo fondo */
  padding: 50px 20px;
  position: relative;
}

.services h2 {
  color: #66fcf1;
  font-size: 2.5rem;
  margin-bottom: 40px;
}

.services-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  max-width: 1000px;
  margin: 0 auto;
}

.service {
  background-color: #0b0c10; /* Fondo más oscuro para cada tarjeta */
  width: 30%;
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.5); /* Sombra para dar profundidad */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Transiciones para la animación */
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 0.5s forwards;
}

.service i {
  font-size: 3rem;
  margin-bottom: 15px;
  color: #66fcf1;
}

.service h3 {
  margin-bottom: 10px;
  color: #66fcf1;
}

.service p {
  color: #c5c6c7;
}

.service:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.7); /* Aumenta la sombra al hacer hover */
}

/* Contact Section */
.contact {
  background-color: #1f2833;
  padding: 50px 20px;
  position: relative;
}

.contact h2 {
  color: #66fcf1;
  font-size: 2.5rem;
  margin-bottom: 40px;
}

.contact form {
  max-width: 800px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.contact form input,
.contact form textarea {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #0b0c10;
  color: #c5c6c7;
  transition: box-shadow 0.3s ease, border 0.3s ease;
}

.contact form input:focus,
.contact form textarea:focus {
  box-shadow: 0 0 10px rgba(102, 252, 241, 0.5);
  border: 1px solid #66fcf1;
}

.contact form button {
  padding: 10px 20px;
  background-color: #66fcf1;
  color: #0b0c10;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.contact form button:hover {
  background-color: #45a29e;
  transform: translateY(-5px);
}

/* Footer */
footer {
  background-color: #0b0c10;
  padding: 20px;
  text-align: center;
  color: #c5c6c7;
  display: flex;
  flex-direction: column;
  align-items: center;
}

footer p {
  margin-bottom: 10px;
}

footer div {
  display: flex;
  gap: 15px;
}

footer a {
  color: #66fcf1;
  text-decoration: none;
  font-size: 1.5rem;
  transition: color 0.3s ease, transform 0.3s ease;
}

footer a:hover {
  color: #45a29e;
  transform: scale(1.2);
}

/* Responsive Design */
@media (max-width: 768px) {
  main {
    margin-left: 0;
    padding: 10px;
  }

  .sidebar {
    width: 100%;
    height: auto;
    position: static;
    flex-direction: row;
    justify-content: space-around;
    padding: 10px 0;
  }

  .home h1 {
    font-size: 2.5rem;
  }

  .home p {
    font-size: 1.2rem;
  }

  .skills-container,
  .projects-container,
  .services-container {
    flex-direction: column;
    align-items: center;
  }

  .skill,
  .project-card,
  .service {
    width: 90%;
  }
}

@media (max-width: 480px) {
  .home h1 {
    font-size: 2rem;
  }

  .home p {
    font-size: 1rem;
  }

  .sidebar nav ul li a {
    font-size: 16px;
  }
}

/* Estilo de fondo de estrellas mejorado */
.stars {
  position: absolute;
  width: 100%;
  height: 100%;
  background: transparent;
  overflow: hidden;
  top: 0;
  left: 0;
  z-index: 0;
  pointer-events: none;
}

.star {
  width: 1px;
  height: 1px;
  background: white;
  position: absolute;
  border-radius: 50%;
  animation: moveStars 80s linear infinite;
  opacity: 0.8;
}

.star:nth-child(odd) {
  top: calc(100% * var(--top));
  left: calc(100% * var(--left));
  width: 1px;
  height: 1px;
}

.star:nth-child(even) {
  top: calc(100% * var(--top));
  left: calc(100% * var(--left));
  width: 2px;
  height: 2px;
}

/* Animación continua para las estrellas */
@keyframes moveStars {
  0% {
    transform: translateY(0) rotate(0deg);
  }
  100% {
    transform: translateY(2000px) rotate(360deg);
  }
}

.server {
  background-color: #1f2833;
  padding: 50px 20px;
  text-align: center;
  color: #c5c6c7;
}

.server h2 {
  color: #66fcf1;
  font-size: 2.5rem;
  margin-bottom: 20px;
  text-shadow: 2px 2px 5px #000;
}

.server-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.server-icon {
  color: #66fcf1;
  margin-bottom: 20px;
}

.server p {
  font-size: 1.5rem;
  margin-bottom: 20px;
}

.discord-link {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #7289da;
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.discord-link:hover {
  background-color: #5b6eae;
}

.section-separator {
  width: 100%;
  height: 5px; /* Puedes ajustar la altura según tu preferencia */
  background-color: #0b0c10; /* Color negro o más oscuro que el fondo */
  margin: 10px 0; /* Espaciado entre secciones */
}

.section-animate {
  opacity: 0;
  transform: translateY(50px); /* Desplaza hacia abajo inicialmente */
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

.section-animate.visible {
  opacity: 1;
  transform: translateY(0); /* Vuelve a la posición original */
}